import * as React from "react"

// Sections
import BannerSection from "../sections/BannerSection"
import ContactSection from "../sections/ContactSection"

import szkola from '../assets/images/szkola-2.jpg'
import Seo from "../components/seo"

const IndexPage = () => {
  return (
    <>
      <BannerSection title="Kontakt" image={<img src={szkola} alt="szkoła" />}/>
      <ContactSection />
    </>
  )
}

export const Head = () => (
  <Seo
    title="Kontakt"
    description="Szkoła Podstawowa im. Emilii Sczanieckiej we Lwówku."
  />
)
export default IndexPage
