import React from "react"
import { Link } from "gatsby"

import Container from "../components/Container"
import Title from "../components/Title"
import Text from "../components/Text"

const ContactSection = () => {
  return (
    <Container as="section" classes="contact-section">
      <div className="contact-section__group">
        <Title as="h2" size="2">
          Skontaktuj się z nami
        </Title>
        <a href="tel:+48661133316" className="contact-section__link">
          <Text as="span">Sekretariat: +48 661 133 316</Text>
        </a>
        <a href="tel:+48518383217" className="contact-section__link">
          <Text as="span">Świetlica: +48 518 383 217</Text>
        </a>
        <a href="tel:+48665133990" className="contact-section__link">
          <Text as="span">Portiernia: +48 665 133 990</Text>
        </a>
      </div>
      <div className="contact-section__group">
        <Title as="h2" size="2">
          Napisz do nas
        </Title>
        <a href="mailto:splwowek@interia.pl" className="contact-section__link">
          <Text as="span">E-mail: splwowek@interia.pl</Text>
        </a>
      </div>
      <div className="contact-section__group">
        <Title as="h2" size="2">
          Odwiedź nas
        </Title>
        <a href="https://goo.gl/maps/8EvrPzvTddNFUCq19" rel="noopener" target='_blank' className="contact-section__link">
          <Text as="span">Lwówek, ul. Gimnazjalna 1, 64-310 Lwówek</Text>
        </a>
      </div>
    </Container>
  )
}

export default ContactSection
